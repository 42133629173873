<template>
  <div class="desktop-date">
    <div class="dates" v-if="dates.length > 1">
      <div class="leftarrow" :class="{ 'enabled': showLeftArrow }" @click="leftArrowAction">
        <img src="../../assets/icons.svg#icon-chevron-left-gradient" alt="*">
      </div>
      <div class="dategrid">
        <div class="date f-tinycta" v-for="(date, index) in dates" :key="date.date" @click="dateSelection(date.date)"
        :class="{
          active: isDateActive(date.date, index),
          disabled: !date.open
        }">
          <div class="dow f8">{{ makeDateParts(date.date).dow }}</div>
          <div class="number f1">{{ makeDateParts(date.date).date }}</div>
          <div class="month f8">{{ makeDateParts(date.date).month }}</div>
          <div class="closed f8" v-if="!date.open">{{ $t("Closed") }}</div>
        </div>
      </div>
      <div class="rightarrow enabled" @click="rightArrowAction">
        <img src="../../assets/icons.svg#icon-chevron-right-gradient" alt="*">
      </div>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import moment from 'moment-timezone';
import DateHelper from '@/core/shared/helpers/DateHelper';
import Loading from '@/core/shared/components/Loading';
import IsMobileMixin from '@/core/shared/misc/IsMobileMixin';

@Component({
  components: {
    Loading,
  },
  props: {
    experienceId: {
      required: true,
    },
    startTime: {
      type: String,
      required: false,
    },
    currentTime: {
      type: String,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
  },
  watch: {
    experienceId: {
      handler () {
        this.$emit('getOpenClosedDates', {
          date: this.dates[0]?.date,
          days: this.days,
        });
      },
    },
  },
})
export default class DateSelector extends mixins(IsMobileMixin) {
  get days () {
    return (this.isMobile) ? 6 : 7;
  }

  get showLeftArrow () {
    if (this.dates[0]) return moment(this.dates[0].date).subtract(1, 'day').diff(moment(this.currentTime)) > 0;
  }

  created () {
    this.$emit('getOpenClosedDates', {
      date: this.currentTime,
      days: this.days,
    });
  }

  onIsMobileChanged () {
    this.$emit('getOpenClosedDates', {
      date: this.dates[0].date,
      days: this.days,
    });
  }

  leftArrowAction () {
    this.$emit('getOpenClosedDates', {
      date: moment(this.dates[0].date).subtract(this.days, 'days').format(),
      days: this.days,
    });
  }

  rightArrowAction () {
    this.$emit('getOpenClosedDates', {
      date: moment(this.dates[0].date).add(this.days, 'days').format(),
      days: this.days,
    });
  }

  dateActive (date) {
    return DateHelper.dateMatch(date, this.startTime);
  }

  isDateActive (date, index) {
    return this.dateActive(date) || (index === 0 && this.startTime === 'now');
  }

  makeDateParts (date) {
    const momentDate = moment(date);
    return {
      dow: (DateHelper.dateMatch(moment(), momentDate)) ? this.$t('Today') : momentDate.format('dddd'),
      date: momentDate.format('DD'),
      month: momentDate.format('MMM'),
    };
  }

  dateSelection (date) {
    this.$emit('update:date', date);
  }
}
</script>

<style scoped lang="postcss">
@import 'core/shared/styles';

.dates {
  display: flex;
  align-items: center;

  & .leftarrow,
  & .rightarrow {
    pointer-events: none;
    transition: opacity 0.3s ease;
    cursor: pointer;
    filter: opacity(0.4);
    flex-shrink: 0;

  &.enabled {
    pointer-events: auto;
    filter: opacity(0.6);

    &:hover {
      filter: opacity(1);
    }
  }

    & img {
      width: 50px;
      height: 50px;
    }
  }

  & .dategrid {
    flex: 1 1 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: repeat(7, 1fr);
    }

    & .date {
      text-align: center;
      border: 0.1rem solid var(--colorGeyser);
      border-radius: var(--radius);
      padding: var(--spacingXS);
      cursor: pointer;
      background-color: white;
      &.active {
        color: var(--colorSBRedFlat);
        border-color: var(--colorSBRedFlat);

        & .dow,
        & .month {
          color: var(--colorSBRedFlat);
        }
      }

      &.disabled {
        cursor: not-allowed;
        border: 0.1rem solid var(--colorManatee);
        color: var(--colorManatee);
        background-color: var(--colorCasper);
        pointer-events: none;
        opacity: 0.2;

        & .month {
          display: none;
        }

        & .dow,
        & .closed {
          color: var(--colorManatee);
        }
      }
    }
  }
}
</style>
