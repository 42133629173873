<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    UIButton,
  },
  props: {
    startTime: {
      type: String,
      required: false,
    },
    timezone: {
      type: String,
      required: true,
    },
    startTimes: {
      type: Array,
      required: true,
    },
    maxPlayersNumber: {
      type: Number,
      required: true,
    },
  },
})
export default class TimeSelector extends Vue {
  startingTimeIndex = 0;

  get startTimesToShow () {
    return this.startTimes.slice(this.startingTimeIndex, this.startingTimeIndex + 7);
  }

  get showLeftArrow () {
    return this.startingTimeIndex !== 0;
  }

  get showRightArrow () {
    return this.startingTimeIndex < this.startTimes.length - 6;
  }

  prettyTime (date) {
    moment.tz.setDefault(this.timezone);
    return moment(date).format('LT');
  }

  leftArrowAction () {
    const newStartTime = this.startingTimeIndex - 6;
    if (newStartTime < 0) {
      this.startingTimeIndex = 0;
    } else {
      this.startingTimeIndex = newStartTime;
    }
  }

  rightArrowAction () {
    const newStartTime = this.startingTimeIndex + 6;
    if (newStartTime >= this.startTimes.length - 6) {
      this.startingTimeIndex = this.startTimes.length - 6;
    } else {
      this.startingTimeIndex = newStartTime;
    }
  }
}
</script>

<template>
  <div class="start-times">
    <div class="start-times-body">
      <div class="left-arrow" :class="{ 'enabled': showLeftArrow }" @click="leftArrowAction">
        <img src="../../assets/icons.svg#icon-chevron-left-gradient" alt="*">
        <!-- <svg><use xlink:href="#icon-chevron-left-gradient"></use></svg> -->
      </div>
      <div class="start-times-grid">
        <!--<div class="start-time" v-for="time in startTimesToShow" :key="time.date">
          <UIButton :ghost="true" class="start-time" @click="$emit('update:time', time.date)" :class="{ active: time.date === startTime }" :disabled="time.maxStations === 0">{{ prettyTime(time.date) }}</UIButton>
          <span class="station-message">{{ $tc("No Station Available | 1 Station Available | {count} Stations Available", time.maxStations, { count: time.maxStations }) }}</span>
        </div>-->
        <div class="dateWrapper" v-for="time in startTimesToShow" :key="time.date">
          <div class="date" @click="$emit('update:time', time.date)" :class="{ active: time.date === startTime, disabled: time.maxStations === 0 || time.maxPlayers < maxPlayersNumber }">
            <div class="dow f3">{{ prettyTime(time.date) }}</div>
          </div>
          <span class="station-message f4">{{ $tc("No Station Available | 1 Station Available | {count} Stations Available", time.maxStations, { count: time.maxStations }) }}</span>
        </div>
      </div>
      <div class="right-arrow" :class="{'enabled': showRightArrow}" @click="rightArrowAction">
        <img src="../../assets/icons.svg#icon-chevron-right-gradient" alt="*">
        <!-- <svg><use xlink:href="#icon-chevron-right-gradient"></use></svg> -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
@import 'core/shared/styles';

.dateWrapper {
  position: relative;
  background-color: white;

  .station-message,
  .player-message {
    font-weight: 100;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    font-size: 12px;
    @media(--tablet) {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .date.active+.station-message  {
    font-weight: 600;
  }
}

.date {
  position: relative;
  text-align: center;
  border: 0.1rem solid var(--colorGeyser);
  border-radius: var(--radius);
  padding: 12px;
  cursor: pointer;
  &.active {
    /* background-color: var(--colorStorm);
    color: var(--colorWhite); */

    border-color: #EC4E36;
    & .dow,
    & .month {
      color: #EC4E36;
      /* color: var(--colorWhite); */
    }
  }

  &.disabled {
    cursor: not-allowed;
    /*border: 0.1rem solid var(--colorCasper);*/
    color: var(--colorManatee);
    background-color: var(--colorCasper);
    pointer-events: none;

    & .month {
      display: none;
    }

    & .dow,
    & .closed {
      color: var(--colorManatee);
    }
  }
}

.start-times {
  width: 100%;
  flex: 1 1 auto;

  @media (--tablet) {
    width: auto;
  }

  & .start-times-body {
    display: flex;
    align-items: center;

    & .left-arrow,
    & .right-arrow {
      flex: 0 1 auto;
      transition: opacity 0.3s ease;
      pointer-events: none;
      cursor: pointer;
      filter: opacity(0.2);

      &.enabled {
        pointer-events: auto;
        filter: opacity(0.4);

        &:hover {
          filter: opacity(1);
        }
      }

      & img {
        width: 50px;
        height: 50px;
      }
    }

    & .start-times-grid {
      flex: 1 1 auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--spacingMd) var(--spacingSm);

      @media (--tablet) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (--desktop) {
        grid-template-columns: repeat(7, 1fr);
      }

      & button {
        padding: var(--spacingSm) var(--spacingXS);
        width: 100%;

        @media (--tablet) {
          padding: var(--spacingSm) var(--spacingMd);
        }
      }
    }
  }
}
</style>
