<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import LocationHelper from '@/core/shared/helpers/LocationHelper';
import UIButton from '@/core/shared/components/ui/UIButton';
import moment from 'moment-timezone';

@Component({
  components: {
    UIButton,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    experience: {
      type: Object,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    location: {
      type: Object,
      required: false,
    },
    priceInfo: {
      type: Object,
      required: false,
    },
    valid: {
      type: Boolean,
      required: false,
    },
    isOrganizationEducation: {
      type: Boolean,
      default: false,
    },
    proceedText: {
      type: String,
      required: true,
    },
    redirectUrl: {
      type: String,
      required: false,
    },
    remainingTime: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasRedirectUrl: function () {
      return !!this.redirectUrl;
    },
  },
})
export default class ReservationBottomBar extends Vue {
  get topDate () {
    if (!this.booking?.startTime || !this.location?.timezone) {
      return '-';
    }
    return `${moment(this.booking.startTime).tz(this.location?.timezone).format('LL')}`;
  }

  get bottomDate () {
    return `${moment(this.booking.startTime).tz(this.location?.timezone).format('LT')}`;
  }

  get isNextDisabled () {
    return !this.booking?.startTime || !this.booking.numberOfPlayers || !this.booking?.bookingStationTimes[0]?.tier?.id;
  }

  toLocationCurrency (number) {
    if (!this.location) {
      return '0.00';
    }
    return LocationHelper.formatApiPriceIntoLocationCurrency(this.location, number);
  }
}
</script>

<template>
  <div class="bottom-bar">
    <div class="info">
      <div class="label f4">1. {{ $t('Selected Experience') }}</div>
      <div class="value f5" v-if="experience">{{ experience.title }}</div>
      <!--<div class="value f5">{{ booking.bookingStationTimes.length }} {{ $tc('station | stations', booking.bookingStationTimes.length) }}</div>-->
    </div>
    <div class="info">
      <div class="label f4">2. {{ $t('Group Size') }}</div>
      <div class="value f5">{{ booking.guests.length }} {{ $tc('person | people', booking.guests.length) }}</div>
      <!--<div class="value f5">{{ booking.bookingStationTimes.length }} {{ $tc('station | stations', booking.bookingStationTimes.length) }}</div>-->
    </div>
    <div class="info">
      <div class="label f4">3. {{ $t('Date') }}</div><!--{{ topDate }}-->
      <div class="value f5">{{ topDate }}</div><!--{{ bottomDate }}-->
    </div>
    <div v-if="!isOrganizationEducation" class="info">
      <div class="label f4">{{ $t("Total Price") }}</div>
      <div class="value f5" v-if="priceInfo && priceInfo.amountTotal">{{ toLocationCurrency(priceInfo.amountTotal) }}</div>
      <div class="value f5" v-else>{{ toLocationCurrency(0) }}</div>
    </div>
    <div class="info">
      <div class="dn label f4">{{ $t("Time Left:") }}
        <span class="red">{{ remainingTime }}</span>
      </div>
      <div class="value">
        <UIButton @click="$emit('proceed')" class="continue" :disabled="isNextDisabled">{{ proceedText }}</UIButton>
      </div>
    </div>
    <!--<UIButton @click="$emit('redirectBack')" v-show="hasRedirectUrl" :style="$store.getters.secondaryButtonStyle" class="cancel">{{ $t('Cancel') }}</UIButton>  :style="$store.getters.secondaryButtonStyle"  -->
  </div>
</template>

<style scoped lang="postcss">
@import 'core/shared/styles';

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  background-color: #f7f8f8;
  padding: var(--spacingXS) var(--spacingSm);
  max-width: 110rem;
  border-top: 1px solid #c9d1da;
  display: grid;
  grid-template-columns: 1fr 1fr;
  z-index: 999;
  @media(--tablet) {
    display: flex;
    justify-content: space-between;
    padding: var(--spacingSm) var(--spacingMd);
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: var(--spacingXS);
  }
  .label {
    font-weight: 800;
  }

  .value {
    margin-top: var(--spacingXS);
    color: var(--colorSBRedFlat);
    font-weight: 600;
    display: flex;
    align-items: center;
    flex: 1;
    @media(--tablet) {
      margin-top: var(--spacingSm);
    }
  }

  @media (--tablet) {
    /* grid-gap: var(--spacingMd);
    grid-template-areas: ". . . cancel proceed"; */
  }

  & .selecthelper {
    grid-column: 1 / 4;
    text-align: center;

    @media (--tablet) {
      text-align: left;
    }
  }

  .continue {
    padding: 8px var(--spacingLg);
  }
}
</style>
