<template>
  <div class="manage-booking container" v-if="show">
    <booking-summary
      v-if="booking"
      :booking="booking"
      :isOrganizationEducation="isOrganizationEducation"
      :redirectUrl="redirectUrl"
      :analyticsPixelUrl="analyticsPixelUrlConfirm"
      @cancelBooking="cancelBooking"
      @redirectBack="redirectBack"
    />
    <div v-else class="no-booking">
      <h1>{{ $t("Look's like there's no reservation here.") }}</h1>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BookingSummary from '../BookingSummary';
import * as Ably from 'ably';

@Component({
  props: {
    customerId: {
      type: String,
      required: true,
    },
    bookingId: {
      type: String,
      required: true,
    },
  },
  components: {
    BookingSummary,
  },
})
export default class ManageBooking extends Vue {
  show = false;
  redirectUrl = null;
  get booking () {
    return this.$store.getters['entities/Booking'](this.bookingId);
  }

  get analyticsPixelUrlConfirm () {
    if (this.$store.state.public.settings && this.$store.state.public.settings.widget && this.$store.state.public.settings.widget.analyticsPixelUrlConfirm) {
      return this.$store.state.public.settings.widget.analyticsPixelUrlConfirm;
    }
  }

  get isOrganizationEducation () {
    if (this.$store.state.public.settings && this.$store.state.public.settings.widget) {
      return this.$store.state.public.settings.widget.organization.type === 'education';
    }
  }

  async created () {
    try {
      const { ablyTokenRequest, paymentStatus } = await this.$store.dispatch('getBooking', { customerId: this.customerId, bookingId: this.bookingId });
      if (paymentStatus === 'initiated') {
        this.$store.dispatch('insertEntities', { name: 'Booking', payload: { id: this.booking.id, _waitForRefresh: true } });
        const client = new Ably.Realtime({
          authCallback: function (tokenParams, callback) {
            callback(undefined, ablyTokenRequest);
          },
        });
        client
          .channels.get(`[?rewind=1]public:App.Booking.${this.bookingId}`)
          .subscribe(async ({ data }) => {
            const { booking } = data;
            if (booking.paymentStatus === 'completed' && this.booking.paymentStatus !== 'completed') {
              this.$store.dispatch('insertEntities', { name: 'Booking', payload: booking });
              await this.$store.dispatch('getBooking', { customerId: this.customerId, bookingId: this.bookingId });
              this.$store.dispatch('insertEntities', { name: 'Booking', payload: { ...booking, _waitForRefresh: false } });
            }
          });
      }
      if (!this.$store.state.public.settings.widget) {
        await this.$store.dispatch('getWidget', { widgetId: this.booking.sourceableId });
      }
      this.redirectUrl = this.$store.state.public.settings.widget.redirectUrl;
    } finally {
      this.show = true;
    }
  }

  cancelBooking () {
    this.$store.commit('setConfirm', {
      message: this.$t('Are you sure you would like to cancel this reservation?'),
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: () => {
            this.$store.dispatch('deleteBooking', { customerId: '1', bookingId: this.bookingId }).then(() => {
              this.$store.commit('setFlash', {
                message: this.$t('Cancelled Booking'),
                type: 'success',
              });
            });
          },
        },
      ],
    });
  }

  async redirectBack () {
    let url = this.redirectUrl;
    if (url.substring(0, 4) !== 'http') {
      url = `https://${url}`;
    }
    location.replace(url);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.manage-booking {
  max-width: 80rem;
  margin-top: 6em;
}
</style>
