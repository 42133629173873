<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import LocationHelper from '@/core/shared/helpers/LocationHelper';
import UIButton from '@/core/shared/components/ui/UIButton';
import AnalyticsPixel from '../../public/booking/components/AnalyticsPixel';

@Component({
  props: {
    booking: {
      type: Object,
      required: true,
    },
    isOrganizationEducation: {
      type: Boolean,
      default: false,
    },
    redirectUrl: {
      type: String,
      default: null,
    },
    analyticsPixelUrl: {
      type: String,
    },
  },
  computed: {
    bookingConfirmationText: function () {
      // Get the first for now, since you can only book one experience at the time. But what if later on, multiple texts need to be presented?
      const experienceId = this.booking.bookingStationTimes[0].experienceId;
      const experience = this.$store.state.public.settings.experiences.find(x => x.id === experienceId);
      if (experience !== null && experience.bookingConfirmationText) {
        return experience.bookingConfirmationText.replaceAll('\n', '<br />');
      }
      return null;
    },
    experience () {
      const experienceId = this.booking.bookingStationTimes[0].experienceId;
      return this.$store.state.public.settings.experiences.find(x => x.id === experienceId);
    },
  },
  components: {
    UIButton,
    AnalyticsPixel,
  },
})
export default class BookingSummary extends Vue {
  get topDate () {
    return moment(this.booking.startTime).format('LL');
  }

  get time () {
    return `${moment(this.booking.startTime).format('LT')} - ${moment(this.booking.bookingStationTimes[0].endTime).format('LT')}`;
  }

  get paid () {
    const amount = this.booking.bookingStationTimes.reduce((amount, bookingStationTime) => {
      return amount + bookingStationTime.amountPaid;
    }, 0);
    return LocationHelper.formatApiPriceIntoLocationCurrency(this.booking.location, amount);
  }

  get unpaid () {
    const amount = this.booking.bookingStationTimes.reduce((amount, bookingStationTime) => {
      return amount + bookingStationTime.amountDue;
    }, 0);
    return LocationHelper.formatApiPriceIntoLocationCurrency(this.booking.location, amount);
  }

  get refundAmount () {
    return this.booking.bookingStationTimes.reduce((amount, bookingStationTime) => {
      return amount + bookingStationTime.amountPaid;
    }, 0);
  }

  manageWaivers () {
    let url = this.booking.location.waiverHostProxyUrl ?? 'https://' + window.sbvrenv.CUSTOMER_URL + '/manage-waivers';
    if (url.indexOf('?') !== -1) {
      url = `${url}${this.booking.waiverReferenceHost}`;
    } else {
      url = `${url}/${this.booking.waiverReferenceHost}`;
    }
    if (url.substring(0, 4) !== 'http') {
      url = `https://${url}`;
    }
    window.open(`${url}`, '_blank');
  }

  getAvatar (firstName, lastName) {
    if (firstName && lastName) return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
    if (firstName) return `${firstName.slice(0, 2)}`;
    return `HO`;
  }

  mounted () {
    if (this.$store.state.public.settings.widget.uaCode) {
      this.$gtm.trackView('Confirmed');
    }
  }
}
</script>

<template>
  <div class="booking-summary">
    <div class="booking-header">
      <img class="cover" :src="experience.imageUrl" :alt="$t('SpringboardVR')">
      <span class="f1 dn">{{ $t(booking.title) }}</span>
    </div>
    <div class="booking-body">
      <h1 class="f3 label ttu mb-md">{{ ($t("Reservation"))}}</h1>
      <div class="grid">
        <div class="when">
          <p class="f3 label">{{ $t('When') }}</p>
          <p class="topdate f3">{{ topDate }}</p>
          <p class="time f3">{{ time }}</p>
        </div>
        <div class="where">
          <p class="f3 label">{{ $t('Where') }}</p>
          <p class="name f3">{{ booking.location.title }}</p>
          <div class="address f3">
            <span v-if="this.booking.location.address">
              {{ this.booking.location.address }}
              <br />
            </span>
            <span v-if="this.booking.location.address2">
              {{ this.booking.location.address2 }}
              <br />
            </span>
            <span v-if="this.booking.location.city">{{ this.booking.location.city }}</span>
            <span v-if="this.booking.location.state">,&nbsp;{{ this.booking.location.state }}</span>
            <span v-if="this.booking.location.postalCode">{{ this.booking.location.postalCode }}</span>
          </div>
        </div>
        <div class="organizer" v-if="booking.host">
          <div class="f3 label">{{ $t('Organizer') }}</div>
          <div class="guest f3">
            <!--<div class="avatar f3">{{ getAvatar(booking.host.firstName, booking.host.lastName) }}</div>-->
            <div class="name">{{ booking.host.firstName }} {{ booking.host.lastName }}</div>
            <div class="email">{{ booking.host.email }}</div>
          </div>
        </div>
        <template v-if="booking._waitForRefresh">
          <div class="amountpaid">
            <div class="paid flex items-center f8">
              <img src="./loading.svg" alt="loading" width="24" height="24" class="dib mr4" />
              <p class="dib">{{ $t('Your payment is being processed...') }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="amountpaid" v-if="!isOrganizationEducation">
            <div class="f3 label">{{ $t('Amount Paid') }}</div>
            <div class="paid f3">{{ paid }}</div>
          </div>
          <div class="amountunpaid" v-if="!isOrganizationEducation">
            <div class="f3 label">{{ $t('Amount Unpaid') }}</div>
            <div class="unpaid f3">{{ unpaid }}</div>
          </div>
        </template>
      </div>
      <div class="waivers f5" v-if="booking.location.waiversRequiredAndContentFilled">
        <p>{{ $t('We want to get you into VR as quickly as possible! Click below to fill in your waivers before you arrive to streamline the arrival process.') }}</p>
        <br />
        <UIButton
          @click="manageWaivers"
        >{{ $t('MANAGE YOUR WAIVERS') }}</UIButton>
      </div>
      <div class="confirmationText f3 " v-if="bookingConfirmationText">
        <p v-html="bookingConfirmationText"></p>
      </div>
      <div class="bottom-bar f6">
        <UIButton
          :secondary="true"
          v-if="!booking.deletedAt"
          class="cancel-button"
          @click="$emit('cancelBooking', booking)"
        >{{ $t('Cancel Reservation') }}</UIButton>
        <UIButton
          v-if="redirectUrl"
          class="redirect-button"
          :secondary="false"
          @click="$emit('redirectBack', booking)"
        >{{ $t('Back to Arcade') }}</UIButton>
        <div
          v-if="!booking.deletedAt && booking.refundable && refundAmount > 0"
        >{{ $t('Cancelling this booking will result in a full refund') }}</div>
        <div
          v-if="!booking.deletedAt && !booking.refundable && refundAmount > 0"
        >{{ $t('This booking is non-refundable') }}</div>
        <h2 class="deleted f2" v-if="booking.deletedAt">{{ $t('This reservation is cancelled') }}</h2>
      </div>
    </div>
    <AnalyticsPixel :url="analyticsPixelUrl"></AnalyticsPixel>
  </div>
</template>

<style scoped lang="postcss">
@import "core/shared/styles";

>>> .f-header, .f3 {
  font-weight: normal;
}

.booking-summary {
  box-shadow: var(--shadowMd);
  background-color: var(--colorWhite);

  .booking-header {
    .cover {
      height: 253px;
      width: 100%;
      object-fit: cover;
    }
  }

  .booking-body {
    padding: var(--spacingMd);
    @media(--table) {
      padding: var(--spacingMd) var(--spacingLg);
    }

    .label {
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  & .ui-button {
    font-weight: 100;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    width: 100%;
    margin-top: var(--spacingSm);

    @media(--tablet) {
      width: auto;
      margin-top: 0;
    }
  }
  & .redirect-button {
    background: var(--colorSBRedFlat);
    &:hover {
      background: var(--colorSBRedFlatHover);
    }
  }

  .grid {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;

      & .where {
        grid-row: 2 / span 2;
        grid-column: 1 / span 1;
      }
      & .organizer {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
      }
    }

    /* & .guest {
      & .avatar {
        grid-row-end: span 2;
        background-color: var(--colorManatee);
        text-align: center;
        line-height: 5rem;
        height: 5rem;
        width: 5rem;
        color: var(--colorWhite);
        margin: 0;
        border-radius: 50%;
        text-transform: uppercase;
      }

      & .name,
      & .email {
        grid-column: 2 / 3;
      }
    } */

    /* & .paid,
    & .unpaid {
      margin: 0;
    } */
  }

  & .waivers {
    justify-content: center;
    text-align: center;
    grid-column: span 2;
    width: 90%;
    margin: auto;
    padding: var(--spacingSm);
    background-color: var(--colorCasper);
  }

  & .confirmationText {
    justify-content: center;
    text-align: center;
    grid-column: span 2;
    width: 90%;
    margin: auto;
    margin-top: var(--spacingMd);
    padding: var(--spacingSm);

    & p >>> b { font-weight: bold; }
    & p >>> i { font-style: italic; }
    & p >>> u { text-decoration: underline; }
  }

  & .bottom-bar {
    /* padding: var(--spacingSm); */
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media(--tablet) {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    & .deleted {
      color: var(--colorSBRedFlat);
    }
  }
}
</style>
