<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import deepmerge from 'deepmerge';
import LocationHelper from '@/core/shared/helpers/LocationHelper';
import moment from 'moment-timezone';
import BirthdatePicker from './BirthdatePicker';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIButton from '@/core/shared/components/ui/UIButton';
import { phonePattern } from '@/core/shared/helpers/RegExpHelper';
import AnalyticsPixel from './AnalyticsPixel.vue';

@Component({
  props: {
    location: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    experience: {
      type: Object,
      required: true,
    },
    gateways: {
      type: Array,
      required: true,
    },
    priceCalculating: {
      type: Boolean,
      required: true,
    },
    priceInfo: {
      type: Object,
      required: false,
    },
    settings: {
      type: Object,
      required: false,
    },
    isOrganizationEducation: {
      type: Boolean,
      default: false,
    },
    selectedGatewayId: {
      type: String,
      required: true,
    },
    analyticsPixelUrl: {
      type: String,
      required: false,
    },
    remainingTime: {
      type: String,
      required: true,
    },
  },
  computed: {
    isPhoneInvalid: function () {
      const value = this.booking.guests[0].phone;
      return !!value && !phonePattern.test(value);
    },
    shouldRenderCoupon: function () {
      return this.booking?.bookingStationTimes?.length > 0;
    },
  },
  components: {
    BirthdatePicker,
    UIInput,
    UIButton,
    AnalyticsPixel,
  },
})
export default class CheckoutPage extends Vue {
  couponCode = '';
  invalidBirthday = false;
  invalidReason = '';

  setBirthday (date) {
    const birthday = moment(date);
    this.invalidBirthday = false;

    // Is the user over 13?
    if (moment().diff(birthday, 'years') >= 13) {
      this.booking.birthday = birthday.utc().format();
    } else {
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t('Sorry, because we want to protect the personal information of everyone 13 and under, you can\'t access the Booking application.'),
      });
      this.booking.birthday = null;
    }
  }

  onBirthdayInvalid ({ reason }) {
    this.booking.birthday = null;
    this.invalidBirthday = true;
    this.invalidReason = reason;
  }

  showErrors () {
    if (this.invalidBirthday) {
      const msg = this.invalidReason === 'year_digits'
        ? 'Your birthday is invalid. Make sure year is written with four digits.'
        : 'Your birthday is invalid.';
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t(msg),
      });
    }
  }

  get topDate () {
    if (!this.booking?.startTime || !this.location?.timezone) {
      return '-';
    }
    return `${moment(this.booking.startTime).tz(this.location?.timezone).format('LL')}`;
  }

  get startTime () {
    return `${moment(this.booking.startTime).tz(this.location?.timezone).format('LT')}`;
  }

  get endTime () {
    return `${moment(this.booking.bookingStationTimes[0].endTime).tz(this.location?.timezone).format('LT')}`;
  }

  get isGatewayPOSOnly () {
    return this.gateways.length === 1 && this.gateways[0].type === 'pos';
  }

  get gatewaysToSelect () {
    return this.gateways.reduce((obj, { type, id }) => {
      let name;
      if (type === 'pos') name = this.$t('Pay in store');
      else if (type === 'paypal') name = this.$t('Pay with PayPal');
      else name = this.$t('Pay with card');
      // square and stripe gateways cannot be both selected so we are choosing the first one if this ever happens
      const CARD_GATEWAYS = ['square', 'stripe'];
      if (CARD_GATEWAYS.includes(Object.keys(obj))) {
        return obj;
      }
      return {
        ...obj,
        [type]: {
          id,
          type,
          name,
        },
      };
    }, {});
  }

  get isFreeBooking () {
    return this.priceInfo && this.priceInfo.amountTotal === 0;
  }

  get widgetLogoUrl () {
    return this.$store.state.public.settings.widget.logoUrl;
  }

  getGatewayName (gateway) {
    switch (gateway.type) {
    case 'pos':
      return this.$t('Pay in store');
    default:
      return this.$t('Pay with card');
    }
  }

  created () {
    this.$emit('calculatePrice');
  }

  mounted () {
    if (this.$store.state.public.settings.widget.uaCode) {
      this.$gtm.trackView('Page3');
    }
  }

  setSelectedGatewayId (gatewayId) {
    this.$emit('update:selectedGatewayId', gatewayId);
  }

  hostInformationSubmit (e) {
    const form = e.target.parentElement.parentElement.parentElement;
    if (!(form && form.checkValidity)) return;
    if (!form.elements.email) return; // This is to handle Birthday picker form triggering this
    if (form.checkValidity()) {
      const newBooking = deepmerge({}, this.booking);
      newBooking.guests[0].email = form.elements.email.value;
      newBooking.guests[0].firstName = form.elements.firstName.value;
      newBooking.guests[0].lastName = form.elements.lastName.value;
      newBooking.guests[0].phone = form.elements.phone.value;
      newBooking.title = form.elements.name.value;
      this.$emit('update:booking', newBooking);
    } else {
      form.querySelector('input[type="submit"]').click();
      const newBooking = deepmerge({}, this.booking);
      newBooking.guests[0].email = '';
      newBooking.guests[0].firstName = '';
      newBooking.guests[0].lastName = '';
      newBooking.guests[0].phone = '';
      newBooking.title = '';
      this.$emit('update:booking', newBooking);
    }
  }

  checkCouponCode (e) {
    e.preventDefault();
    this.$emit('calculatePrice', this.couponCode);
  }

  toLocationCurrency (number) {
    return LocationHelper.formatApiPriceIntoLocationCurrency(this.location, number);
  }

  back () {
    this.$emit('back');
  }
}
</script>

<template>
  <div class="checkout-page">
    <div class="header-section">
      <h1 class="f1 title">{{ ($t("Add Personal Details")).toUpperCase() }}</h1>
      <div class="timer f1">
        <span>{{ ($t("time remaining")).toUpperCase() }}:</span>
        <span class="time">{{remainingTime}}</span>
      </div>
    </div>
    <div class="main-section info">
      <div class="half form">
        <form class="hostinformation" @change="hostInformationSubmit">
          <UIInput class="input-group" :title="$t('First Name ')" type="text" name="firstName" id="firstName" required />
          <UIInput class="input-group" :title="$t('Last Name ')" type="text" name="lastName" id="lastName" required />
          <div class="input-group birthday">
            <label class="label f2" for="birthday">
              {{ $t('Birthday') }}
              <span class="required" v-if="this.experience.bookingBirthdateRequired">*</span>
              <a
                href="https://springboardvr.com/legal/why-birthday"
                rel="noopener"
                target="_blank"
                class="link"
              >{{ $t('Why do we need this?') }}</a>
            </label>
            <BirthdatePicker
              @change="setBirthday"
              @invalid="onBirthdayInvalid"
              :mdy-formatting="$i18n.locale === 'en-US' || $i18n.locale === 'en-us'"
              @blur="showErrors"
            />
          </div>
          <UIInput class="input-group" :title="$t('Email ')" type="email" name="email" id="email" required />
          <UIInput class="input-group" :title="$t('Phone')" type="phone" name="phone" id="phone" required :error="isPhoneInvalid" />
          <UIInput class="input-group name" :title="$t('Reservation Name')" type="text" name="name" id="name" />
          <div class="coupon-row flex justify-between" v-if="shouldRenderCoupon">
            <!-- <form @submit="checkCouponCode"> -->
              <div class="coupon  w-100 mr4">
                <label class="label f2" for="couponCode">{{ $t("Coupon Code") }}</label>
                <UIInput type="text f5" name="couponCode" id="couponCode" v-model="couponCode" />
              </div>
              <UIButton :ghost="true" class="submit h-100" :disabled="priceCalculating" @click="checkCouponCode">
                <span v-if="!priceCalculating">{{ $t('Submit') }}</span>
                <span v-else>{{ $t('Checking Code') }}</span>
              </UIButton>
            <!-- </form> -->
          </div>
          <span class="helper f7">{{ $t('* Required Fields') }}</span>
          <div class="terms">
            <label class="checkbox-label">
              <input type="checkbox" name="agree" v-model="booking.agree" />
              <i18n
                class="copy f5"
                tag="span"
                path="I have read and agree to the {terms_of_service} and {privacy_policy}"
              >
                <template v-slot:terms_of_service>
                  <a
                    class="link"
                    href="https://springboardvr.com/legal/springboard-vr-scheduler-terms-of-service"
                    rel="noopener"
                    target="_blank">
                    {{ $t('terms of service') }}
                  </a>
                </template>
                <template v-slot:privacy_policy>
                  <a
                    class="link"
                    href="https://springboardvr.com/legal/privacy-policy"
                    rel="noopener"
                    target="_blank">
                    {{ $t('privacy policy') }}
                  </a>
                </template>
              </i18n>
            </label>
          </div>
          <input type="submit" class="hiddensubmit" />
        </form>
      </div>
      <div class="half preview">
        <div class="image" :style="{ backgroundImage: `url(${experience.imageUrl})` }" :alt="experience.title"></div>
        <div class="price details" v-if="!isOrganizationEducation">
          <div class="grid">
            <h2 class="f3 title ttu">{{ $t("Your Booking Details") }}</h2>
            <h2 class="f3 title ttu">{{ $t("Total") }}</h2>
          </div>
          <div class="price-summery grid">
            <h2 class="f3 title ttu">{{ experience.title }}</h2>
            <h2 class="f3 title ttu" v-if="priceInfo">{{ toLocationCurrency(priceInfo.amountTotal) }}</h2>
          </div>
          <div class="grid">
            <div class="subtotal">
              <div class="label f3 ttu mb2">{{ $t('Booking Details') }}</div>
              <div class="value f5 b">
                <p>{{ location.title }}</p>
                <p>{{ topDate }}</p>
                <p>{{ startTime }} - {{ endTime }}</p>
              </div>
            </div>
            <div class="total">
              <div class="label f3 ttu mb2">{{ $t('Location') }}</div>
              <div class="value f5">
                <p>{{ location.address }}</p>
                <p v-if="location.address2 && location.address2 != ''">{{ location.address2 }}</p>
                <p>{{ location.city }}</p>
                <p>{{ location.postalCode }}</p>
                <p v-if="location.state && location.state != ''">{{ location.state }}</p>
                <p>{{ location.country }}</p>
              </div>
            </div>
          </div>
          <div class="pricesummary"> <!-- v-if="priceInfo" -->
            <!--<div class="subtotal">
              <div class="label f4">{{ $t('Subtotal') }}</div>
              <div class="value f5">{{ toLocationCurrency(priceInfo.subTotal) }}</div>
            </div>
            <div class="coupon" v-if="priceInfo.coupon">
              <div class="label f4">{{ $tc('Coupon', 1) }} - {{ priceInfo.coupon.code }}</div>
              <div class="value f5">- {{ toLocationCurrency(priceInfo.couponTotal) }}</div>
            </div>
            <div class="discount" v-if="priceInfo.discount">
              <div class="label f4">{{ $tc('Discount', 1) }}</div>
              <div class="value f5">- {{ toLocationCurrency(priceInfo.discountTotal) }}</div>
            </div>
            <div class="tax" v-if="priceInfo.taxTotal">
              <div class="label f4">
                {{ $t('Tax') }}
                <span v-if="!priceInfo.taxExclusive">{{ $t("(Included)") }}</span>
              </div>
              <div class="value f5">{{ toLocationCurrency(priceInfo.taxTotal) }}</div>
            </div>
            <div class="total">
              <div class="label f4">{{ $t('Total') }}</div>
              <div class="value f5">{{ toLocationCurrency(priceInfo.amountTotal) }}</div>
            </div>-->
          </div>
          <div class="paymenttype" v-if="priceInfo && gateways.length > 1 && !isFreeBooking">
            <h2 class="f3 title">{{ $t('Payment Type') }}:</h2>
            <div class="buttons">
              <UIButton
                ghost
                v-for="gateway in gatewaysToSelect"
                :key="gateway.id"
                @click="setSelectedGatewayId(gateway.id)"
                :class="{ '-active': selectedGatewayId === gateway.id }"
              >{{ gateway.name }}</UIButton>
            </div>
          </div>
          <div
            class="posonly f4"
            v-if="isGatewayPOSOnly"
          >{{ $t('Payment will be taken upon your arrival at the location.') }}</div>
          <UIButton
            type="submit"
            :link="true"
            class="change-btn"
            @click="back">
            <span>{{ $t('Change Booking') }}</span>
          </UIButton>
        </div>
      </div>
    </div>
    <div class="powered-by mt5 flex justify-center">
      <img :src="widgetLogoUrl" class="w-20" alt="Powered by SpringboardVR" />
    </div>
    <AnalyticsPixel :url="analyticsPixelUrl"></AnalyticsPixel>
  </div>
</template>

<style scoped lang="postcss">
@import "core/shared/styles";

.checkout-page {
  position: relative;
  display: grid;
  grid-gap: var(--spacingMd);

  .header-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-section,
  .main-section {
    @media(--tablet){
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .title {
    font-weight: 800;
  }

  .timer {
    font-size: 30px;
    font-weight: 100;
    .time {
      color: var(--colorSBRedFlat);
      font-weight: 600;
    }
  }

  & .form {
    margin: auto;

    @media(--tablet) {
      margin-right: 40px;
    }

    @media(--desktop) {
      max-width: 420px;
      margin-left: 0;
      margin-right: auto;
    }

    .label {
      font-size: 1.4em;
      font-weight: 600;
    }

    .input-group {
      margin-bottom: var(--spacingXS);
    }

    .ui-input,
    .ui-select {
      padding: 8px var(--spacingSm);
    }
  }

  & .preview {
    .image {
      width: 100%;
      height: 250px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: var(--spacingMd);
      margin-top: var(--spacingSm);
    }

    .grid {
      display: grid;
      grid-template-columns: 3fr 1fr;
    }

    .price-summery {
      color: var(--colorSBRedFlat);
      position: relative;
      margin-top: var(--spacingXS);
      &:after {
        content: '';
        display: block;
        background-color: #cccdd3;
        width: 100%;
        height: 1px;
        margin: var(--spacingSm) 0 var(--spacingSm);
        grid-column: span 2;
      }
    }

    .change-btn {
      @apply --f3;
      font-weight: 100;
      color: var(--colorSBRedFlat);
      text-align: right;
      display: block;
      margin-left: auto;
      margin-top: var(--spacingSm);

      &:hover {
        color: var(--colorSBRedFlatHover);
      }
    }
  }

  .details {
    width: 90%;
  }

  .terms {
    .checkbox-label {
      display: flex;
    }

    .copy {
      @apply --f4;
      font-size: 1.2em;
      letter-spacing: 0;
      margin-left: var(--spacingXS);
    }
  }

  .link {
    text-decoration: underline;
    letter-spacing: 0px;
    color: var(--colorInfoFlat);
    &:hover {
      color: var(--colorInfoFlatHover);
    }
  }

  .birthday .link {
    font-size: 0.8em;
  }

  & .hostinformation {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }

    & .input-group {
      display: flex;
      flex-direction: column;
    }

    & .hiddensubmit {
      display: none;
    }

    & .required {
      color: var(--colorSBRedFlat);
    }
  }

  & .agree label {
    display: flex;
    margin: var(--spacingSm) 0;
    height: 1.3rem; /* Fix for Edge and Firefox */
    & .copy {
      position: relative;
      margin-left: var(--spacingXS);
      bottom: 0.5rem;
    }
  }

  & .pricesummary {
    display: flex;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);

    @media (--desktop) {
      grid-template-columns: repeat(5, 1fr);
    }

    & .total {
      min-width: 30%;
      & .value {
        display: flex;
        flex-direction: column;
      }
    }

    & .subtotal {
      min-width: 30%;
      & .value {
        display: flex;
        flex-direction: column;
      }
    }
  }

  & .gateways {
    display: flex;
    margin-bottom: var(--spacingSm);
  }

  & .coupon-row {
    margin-top: auto;

    .submit {
      margin-top: auto;
      height: 2.7em;
      width: 20em;
      padding-top: 0.6em;
      padding-bottom: 0.6em;
    }
  }
  & .coupon {
    & .openbutton {
      display: inline-block;
      padding: 0;
      margin: var(--spacingSm) 0 0 0;
      text-decoration: underline;
    }

    & form {
      display: flex;
      align-items: center;
      margin-top: var(--spacingMd);

      & .input-group {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        margin-right: var(--spacingSm);
        margin-top: auto;

        & .couponlabel {
          margin-bottom: var(--spacingXS);
        }
      }
    }
  }
  & .posonly {
    margin-top: var(--spacingMd);
  }

  & .paymenttype {
    margin-top: var(--spacingMd);
    & .title{
      margin-bottom: 1rem;
      font-weight: normal;
    }
    & .buttons {
      & .ui-button.-active {
        background-color: var(--colorSBRedFlat);
        border-color: var(--colorSBRedFlat);
      }

      & > * {
        margin-right: var(--spacingSm);
        margin-bottom: var(--spacingSm);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
