export const buttonStyle = state => {
  if (state.settings.widget) {
    return {
      color: '#' + state.settings.widget.colorButtonText,
      background: '#' + state.settings.widget.colorButtonBackground,
      borderColor: '#' + state.settings.widget.colorButtonBackground,
    };
  } else {
    return {
      color: '#ffffff',
      background: '#ec4e36',
      borderColor: '#ec4e36',
    };
  }
};

export const secondaryButtonStyle = state => {
  return {
    color: '#ec4e36',
    background: '#ffffff',
    border: '1px solid #ec4e36',
  };
};

export const dates = state => {
  return state.dates;
};
