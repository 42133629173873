<template>
  <div class="group-selector flex">
    <div class="group"
      v-for="index in getGroupSelectorSize"
      :class="[
      'groupSelectButton',
      {
        active: isActive(index),
        disabled: isDisabled(index)
      }]"
      :key="index"
      @click="setNumberOfPlayers(index)"
      @mouseover="onMouseOver(index)"
      @mouseleave="resetHover()">
        <svg class="person-icon"><use xlink:href="#icon-person-alt"></use></svg>
        <div class="size-bubble">
          <span>{{ index }}</span>
        </div>
        <div class="arrow"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    experience: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    numberOfPlayers: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
  },
})
export default class GroupSizeSelector extends Vue {
  groupSizeButtonHoverIndex = 0;

  get getGroupSelectorSize () {
    const selecorMaxSize = this.getGroupSelectorLimit;
    return selecorMaxSize < 8 ? 8 : selecorMaxSize;
  }

  get getGroupSelectorLimit () {
    return this.experience?.maxPeoplePerStation * this.experience?.stationsCount;
  }

  resetHover () {
    this.groupSizeButtonHoverIndex = 0;
  }

  onMouseOver (index) {
    this.groupSizeButtonHoverIndex = (index > this.getGroupSelectorLimit ? 0 : index);
  }

  isActive (index) {
    return (index <= this.numberOfPlayers) || (this.groupSizeButtonHoverIndex > 0 && index <= this.groupSizeButtonHoverIndex);
  }

  isDisabled (index) {
    return index > this.getGroupSelectorLimit;
  }

  setNumberOfPlayers (players) {
    if (players > this.getGroupSelectorLimit) {
      return;
    }
    this.$emit('onChange', players);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.group-selector {
  width: 100%;
  flex-wrap: wrap;
  padding-right: var(--spacingMd);

  & .group {
    width: 100%;
    position: relative;
    background-color: #D5D8DF;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #F7F8F8;
    border-right-color: #fff;
    padding-top: 20px;
    padding-right: 15px;
    width: 25%;
    height: 80px;

    @media(--tablet){
      width: 12.5%;
      /* flex-wrap: wrap; */
      /* padding-right: 0; */
    }

    & .arrow {
      position: absolute;
      top: 0;
      right: -24px;
      height: 46px;
      width: 46px;
      background: inherit;
      transform: translateY(16px) rotate(45deg) skew(12deg, 12deg);
      border-top: #fff solid 1px;
      border-right: #fff solid 1px;
      z-index: 2;
    }

    & .person-icon {
      width: 40px;
      height: 100%;
      fill: #fbfcfce6;
      z-index: 10;
      margin-bottom: 5px;
      z-index: 30;
    }

    &.disabled {
      background-color: #f1f1f1;
      cursor: not-allowed;
    }

    &.active:not(.disabled) {
      background-color: #fff;
      border-color: var(--colorSBRedFlat);

      & .arrow {
        border-color: var(--colorSBRedFlat);
      }
      & .person-icon {
        fill: #879AB2;
      }

      & .size-bubble {
        color: #879AB2;
        border-color: #879AB2;
      }
    }
  }
  & .size-bubble {
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 20px;
    width: 1.4em;
    height: 1.4em;
    border-radius: 100%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 14px;
    left: 44%;
    z-index: 20;
    @media(--tablet) {
      font-size: 22px;
      width: 1.6em;
      height: 1.6em;
    }
  }
}

</style>
