<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import deepmerge from 'deepmerge';

import UIButton from '@/core/shared/components/ui/UIButton';
import StationSelector from './components/StationSelector';
import CheckoutPage from './components/CheckoutPage';
import ReservationBottomBar from './components/ReservationBottomBar';
import PageSwitcher from './components/PageSwitcher';
import DateSelector from './components/DateSelector';
import TimeSelector from './components/TimeSelector';
import GroupSizeSelector from './components/GroupSizeSelector';

import moment from 'moment-timezone';
import Loading from '@/core/shared/components/Loading';
import { mapGetters } from 'vuex';
import { get } from 'lodash-es';
import { phonePattern } from '@/core/shared/helpers/RegExpHelper';

@Component({
  props: {
    widgetId: {
      type: String,
      required: true,
    },
  },
  computed: mapGetters([
    'dates',
    'remainingTime',
  ]),
  components: {
    UIButton,
    StationSelector,
    CheckoutPage,
    ReservationBottomBar,
    Loading,
    PageSwitcher,
    DateSelector,
    TimeSelector,
    GroupSizeSelector,
  },
})
export default class PublicBooking extends Vue {
  page = 0;
  timerEndTime = null;
  timer = null; // 15 minutes: 60 seconds * 15
  intervalId = null;

  sessionId = null;

  loading = true;
  priceCalculating = false;

  today = moment().format();
  priceInfo = null;
  selectedGatewayId = null;
  date = null;
  time = null;
  numberOfPlayers=1;

  booking = {
    title: null,
    agree: false,
    startTime: null,
    birthday: null,
    location: {
      id: null,
    },
    bookingStationTimes: [
      {
        experience: {
          id: null,
        },
        station: {
          id: null,
        },
        tier: {
          id: null,
        },
        amountDue: null,
        amountPaid: null,
        discount: null,
        coupon: null,
        startedAt: null,
      },
    ],
    guests: [
      {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
      },
    ],
  };
  transactionId = null;
  pendingRedirection = false;

  get experiences () {
    return this.settings.experiences?.sort((a, b) => a.order - b.order);
  }

  get settings () {
    return this.$store.state.public.settings;
  }

  get valid () {
    if (this.page === 0) return false;
    if (this.page === 1) return !!this.booking.startTime && !!this.settings.startTimes && !!this.booking.bookingStationTimes[0].tier.id;
    if (this.page === 2) {
      const phoneIsValid = !this.booking.guests[0].phone || phonePattern.test(this.booking.guests[0].phone);
      let conditions = [];
      if (!this.isOrganizationEducation) {
        conditions = [
          !!this.booking.guests[0].phone,
          !!this.booking.agree,
          !!this.priceInfo,
          !!this.booking.guests[0].firstName,
          !!this.booking.guests[0].lastName,
          !!this.booking.guests[0].email,
          phoneIsValid,
        ];
      } else {
        conditions = [
          !!this.booking.guests[0].phone &&
          !!this.booking.agree &&
          !!this.booking.guests[0].firstName &&
          !!this.booking.guests[0].lastName &&
          !!this.booking.guests[0].email &&
          phoneIsValid,
        ];
      }
      if (this.experience.bookingBirthdateRequired) {
        conditions.push(!!this.booking.birthday);
      }
      return conditions.every(Boolean);
    }
  }

  get widget () {
    return this.$store.state.public.settings.widget;
  }

  get isOrganizationEducation () {
    if (this.widget) {
      return this.widget.organization.type === 'education';
    }
  }

  get startTimeMinMax () {
    if (this.settings.startTimes) {
      return this.settings.startTimes.find(startTime => startTime.date === this.booking.startTime);
    }
  }

  get experience () {
    if (this.settings.experiences) {
      return this.settings.experiences.find(experience => experience.id === this.booking.bookingStationTimes[0].experience.id);
    }
  }

  get proceedText () {
    if (this.page < 2) return this.$t('Next').toUpperCase();
    return this.$t('Checkout').toUpperCase();
  }

  get redirectText () {
    const gateway = this.settings.gateways.find(gateway => this.selectedGatewayId === gateway.id);
    if (!gateway || gateway.type === 'pos') {
      return this.$t('Please wait, you are about to be redirected to the booking confirmation page.');
    }
    return this.$t('You are about to be redirected to complete the payment...');
  }

  get redirectUrl () {
    if (this.$store.state.public.settings.widget) {
      return this.$store.state.public.settings.widget.redirectUrl;
    }
  }

  get checkoutLink () {
    return `${window.sbvrenv.CHECKOUT_URL}/payments/${this.transactionId}/redirect`;
  }

  getGroupSizeIconCount (exp) {
    return exp.maxPeoplePerStation > 6 ? 1 : exp.maxPeoplePerStation;
  }

  async created () {
    await this.$store.dispatch('getWidget', { widgetId: this.widgetId });
    moment.tz.setDefault(this.settings.timezone);
    this.page = 1;

    this.booking.location.id = this.settings.location.id;
    this.booking.bookingStationTimes[0].experience.id = this.experiences[0].id;

    if (!this.isOrganizationEducation) {
      this.selectedGatewayId = this.settings.gateways[0].id;
    }

    this.date = this.booking.startTime;
    this.time = this.booking.startTime;

    this.loading = false;
  }

  onPageUpdate (page) {
    this.page = page;
  }

  get hours () {
    return this.experiences?.find(experience => this.isExperienceSelected(experience)).hours;
  }

  isExperienceSelected (experience) {
    return experience.id === this.booking.bookingStationTimes[0].experience.id;
  }

  onBookingUpdate (booking) {
    this.booking = booking;
  }

  onTierUpdate () {
    this.calculatePrice();
  }

  experienceAction (experience) {
    this.numberOfPlayers = 1;
    this.booking.numberOfPlayers = 1;
    this.setNumberOfPlayers(1);
    const newBooking = deepmerge({}, this.booking);
    newBooking.bookingStationTimes[0].experience.id = experience.id;
    this.date = null;
    this.time = null;
    this.onBookingUpdate(newBooking);
  }

  onDateUpdated (date) {
    this.time = null;
    this.priceInfo = null;
    const newBooking = deepmerge({}, this.booking);
    newBooking.bookingStationTimes[0].tier.id = null;
    newBooking.startTime = null;
    this.onBookingUpdate(newBooking);

    this.date = date;

    this.getStartingTimesForDate(date, newBooking.numberOfPlayers);

    setTimeout(() => {
      window.scroll(0, document.documentElement.offsetHeight);
    }, 100);
  }

  onTimeUpdate (time) {
    this.time = time;
    const newBooking = deepmerge({}, this.booking);
    newBooking.startTime = time;
    this.onBookingUpdate(newBooking);

    setTimeout(() => {
      window.scroll(0, document.documentElement.offsetHeight);
    }, 100);
  }

  onSelectedGatewayIdUpdate (selectedGatewayId) {
    this.selectedGatewayId = selectedGatewayId;
  }

  getOpenClosedDates ({ date, days }) {
    this.$store.dispatch('getOpenClosedDates', { widgetId: this.widgetId, booking: this.booking, startDate: date, endDate: moment(date).add(days - 1, 'days').format() });
  }

  getStartingTimesForDate (date, numberOfPlayers) {
    this.$store.dispatch('getAvailableTimes', { widgetId: this.widgetId, booking: this.booking, date, numberOfPlayers });
  }

  getTiersForPlayers () {
    this.priceInfo = null;
    this.$store.dispatch('getAvailableTiers', { widgetId: this.widgetId, booking: this.booking });
  }

  async calculatePrice (couponCode) {
    this.priceCalculating = true;
    try {
      const priceInfo = await this.$store.dispatch('calculatePrice', { widgetId: this.widgetId, booking: this.booking, couponCode });

      this.booking.bookingStationTimes.forEach(bookingStationTime => {
        bookingStationTime.amountDue = priceInfo.amountDue;
        if (priceInfo.coupon) bookingStationTime.coupon = { id: priceInfo.coupon.id };
        if (priceInfo.discount) bookingStationTime.discount = { id: priceInfo.discount.id };
      });

      this.priceInfo = priceInfo;
    } finally {
      this.priceCalculating = false;
    }
  }

  proceed () {
    if (!this.valid) {
      return;
    }

    window.scrollTo(0, 0);
    if (this.page < 2) {
      this.startCheckout();
    } else {
      this.makePayment();
    }
  }

  setNumberOfPlayers (players) {
    this.numberOfPlayers = players;
    this.booking.numberOfPlayers = players;
    this.priceInfo = null;
    this.time = null;

    // reset chosen fiedls
    const newBooking = deepmerge({}, this.booking);
    newBooking.guests = newBooking.guests.slice(0, players);
    newBooking.bookingStationTimes[0].tier.id = null;
    for (let i = 0; i < players; i++) {
      newBooking.guests[i] = deepmerge({}, this.booking.guests[0]);
    }
    newBooking.startTime = null;
    this.onBookingUpdate(newBooking);

    if (this.date) {
      this.$store.dispatch('getAvailableTimes', { widgetId: this.widgetId, booking: newBooking, date: this.date, numberOfPlayers: this.numberOfPlayers });
    }

    setTimeout(() => {
      window.scroll(0, document.documentElement.offsetHeight);
    }, 100);
    // this.setNumberOfStations(this.stationAmountsBasedOnPlayers[0].amount);
  }

  redirectBack () {
    let url = this.redirectUrl;
    if (url.substring(0, 4) !== 'http') {
      url = `https://${url}`;
    }
    location.assign(url);
  }

  async makePayment () {
    this.loading = true;
    this.pendingRedirection = true;

    try {
      const payload = { widgetId: this.widgetId,
        booking: {
          ...this.booking,
          description: this.settings.location.name,
        },
        payment: {
          gateway: this.selectedGatewayId,
        },
        sessionId: this.sessionId,
      };
      const booking = await this.$store.dispatch('makeBooking', payload);
      this.transactionId = get(booking, 'transactions[0].gateway_transaction_id');
      // if this is free we are redirect directly
      if (!this.transactionId) {
        this.loading = false;
        this.pendingRedirection = false;
        this.$router.push({ name: 'manage-booking', params: { customerId: booking.hostId, bookingId: booking.id } });
        return;
      }
      window.open(this.checkoutLink, '_self');
    } catch (e) {
      this.loading = false;
      throw e;
    }
  }

  async back () {
    const payload = {
      sessionId: this.sessionId,
    };
    const status = await this.$store.dispatch('changeBooking', { widgetId: this.widgetId, payload });
    if (status) {
      this.sessionId = null;
      this.resetTimer();
    }
  }

  startTimer () {
    this.timerEndTime = moment().add(15, 'minutes');

    this.intervalId = setInterval(() => {
      this.timer = this.timerEndTime.diff(moment(), 'seconds');
      if (this.timer == null || this.timer <= 0) {
        this.resetTimer();
        this.$store.commit('setFlash', {
          message: this.$t('Your time is expired'),
          type: 'error',
        });
      }
    }, 200);
  }

  resetTimer () {
    clearInterval(this.intervalId);
    this.timerEndTime = null;
    this.timer = null;
    this.page = 1;
  }

  async startCheckout () {
    const experienceId = this.booking.bookingStationTimes[0].experience.id;
    const payload = {
      startTime: this.booking.startTime,
      numberOfStations: this.booking.bookingStationTimes.length,
      tierId: this.booking.bookingStationTimes[0].tier.id,
    };

    const sessionId = await this.$store.dispatch('startCheckout', { widgetId: this.widgetId, experienceId, payload });
    if (sessionId) {
      this.sessionId = sessionId;
      this.startTimer();
      this.page++;
    }
  }

  get remainingTime () {
    return moment.utc(this.timer * 1000).format('mm:ss');
  }
}
</script>

<template>
  <div class="public-booking">
    <h1 class="locationname" v-if="!loading">{{ settings.location.name }}</h1>
    <!--<PageSwitcher :page="page" @update:page="onPageUpdate" />-->
    <div class="panel">
      <div v-show="!loading" class="loading-container">
        <div class="pages">
          <!--<h2>{{ $t("Step {currentStep} of {stepsLength}", { currentStep: page, stepsLength: 3}) }}</h2>-->
          <template v-if="page === 1">
            <h1 class="section-title">1. {{ ($t("Select Experience")).toUpperCase() }}</h1>
            <div class="experiences">
              <div class="experience" v-for="experience in experiences" :key="experience.id"
                   :class="{ 'active': isExperienceSelected(experience) }">
                <div @click="experienceAction(experience)">
                  <div class="experience-header flex justify-between mb-xs">
                    <span class="title">{{ experience.title }}</span>
                    <div class="flex">
                      <template v-for="index in getGroupSizeIconCount(experience)">
                        <svg :key="index" class="member"><use xlink:href="#icon-person-alt"></use></svg>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="image" :style="{ outlineColor: $store.getters.buttonStyle.borderColor }">
                  </div> -->
                  <div class="image">
                    <img class="w-100" :src="experience.imageUrl" :alt="experience.title" />
                  </div>
                </div>
                <span class="description f6">
                  {{ experience.description }}
                </span>
                <!--<div class="timeSlots">
                  <div class="timeSlot" v-for="index in 4" :key="index">
                    <span class="durationText">{{ '30min' }}</span>
                    <span class="dateText">{{ '(01.01)' }}</span>
                  </div>
                </div>-->
              </div>
            </div>

            <div class="separator"></div>

            <div class="part2">
              <h2 class="section-title">2. {{ ($t("What is your group size?")).toUpperCase() }}</h2>
              <GroupSizeSelector
                :experience="experience"
                :booking="booking"
                :numberOfPlayers="numberOfPlayers"
                @onChange="setNumberOfPlayers"
              />
            </div>

            <div class="separator"></div>

            <div class="part3">
              <h2 class="section-title">3. {{ ($t("Choose your date & Starting time")).toUpperCase() }}</h2>

              <div class="dates" v-if="!loading && dates">
                <!--<div class="label f8">{{ $t('Select a Date') }}</div>-->
                <DateSelector :experienceId="booking.bookingStationTimes[0].experience.id" :startTime="date" :currentTime="today" :dates="dates" @update:date="onDateUpdated" @getOpenClosedDates="getOpenClosedDates" v-on="$listeners" />
              </div>
              <div class="times" v-if="date">
                <!--<div class="label f8">{{ $t('Select a Starting Time') }}</div>-->
                <TimeSelector
                  v-if="settings.startTimes"
                  :startTime="time"
                  :startTimes="settings.startTimes"
                  :timezone="settings.location.timezone"
                  :maxPlayersNumber="numberOfPlayers"
                  @update:time="onTimeUpdate"
                />
                <Loading v-else />
              </div>
            </div>

            <div class="separator"></div>

            <div class="part4" v-if="date && time">
              <h2 class="section-title">4. {{ ($t("Select Station & Duration")).toUpperCase() }}</h2>
              <StationSelector
                :location="settings.location"
                :booking="booking"
                :tiers="settings.tiers"
                :startTimeMinMax="startTimeMinMax"
                :stationAmounts="settings.stationAmounts"
                :experience="experience"
                :isOrganizationEducation="isOrganizationEducation"
                :analyticsPixelUrl="settings.widget.analyticsPixelUrlStep2"
                @getTiersForPlayers="getTiersForPlayers"
                @update:booking="onBookingUpdate"
                @onTierUpdate="onTierUpdate"
              />
            </div>

            <div class="bg-image" v-if="widget.backgroundImageUrl" :style="`background-image: url('${widget.backgroundImageUrl}');`"></div>
          </template>

          <CheckoutPage
            v-if="page == 2"
            :location="settings.location"
            :gateways="settings.gateways"
            :booking="booking"
            :experience="experience"
            :priceCalculating="priceCalculating"
            :priceInfo="priceInfo"
            :settings="settings"
            :selectedGatewayId="selectedGatewayId"
            :isOrganizationEducation="isOrganizationEducation"
            :analyticsPixelUrl="settings.widget.analyticsPixelUrlStep3"
            :remainingTime="remainingTime"
            @update:selectedGatewayId="onSelectedGatewayIdUpdate"
            @update:booking="onBookingUpdate"
            @calculatePrice="calculatePrice"
            @back="back"
          />
        </div>
        <ReservationBottomBar
          v-if="experience"
          :booking="booking"
          :experience="experience"
          :page="page"
          :location="settings.location"
          :valid="valid"
          :priceInfo="priceInfo"
          :isOrganizationEducation="isOrganizationEducation"
          :proceedText="proceedText"
          :redirectUrl="redirectUrl"
          :remainingTime="remainingTime"
          @proceed="proceed"
          @redirectBack="redirectBack"
        />
      </div>
      <div class="loadinganimation" v-if="loading">
        <Loading />
        <div v-if="pendingRedirection" class="tc mb4">
          <p class="f-body">{{ redirectText }}</p>
          <i18n class="f-body" path="If nothing happens {link}." v-if="transactionId">
            <template #link>
              <UIButton
                link
                :href="checkoutLink"
                rel="noopener"
                target="_blank"
              >{{ $t('click here') }}</UIButton>
            </template>
          </i18n>
        </div>
      </div>
    </div>
    <!--<div class="powered">
      <a href="https://springboardvr.com" rel="noopener" target="_blank">
        <img src="/images/power.png" :alt="$t('Powered By SpringboardVR')" />
      </a>
    </div>-->
  </div>
</template>

<style>

html {
  scroll-behavior: smooth;
}

</style>

<style scoped lang="postcss">
@import "core/shared/styles";

.public-booking {
  @apply --contentContainer;
  min-height: calc(100vh - 6rem);
  max-width: 120rem;
  padding: 0 var(--spacingM);
  padding-bottom: 140px !important;
  overflow-x: hidden;

  @media (--desktop) {
    padding-bottom: 100px !important;
    padding: var(--spacingLg) var(--spacingMd);
  }

  .section-title {
    @apply --f2;
    font-size: 26px;
    font-weight: 800;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: var(--spacingSm);

    @media (--tablet) {
      font-size: 35px;
      margin-bottom: var(--spacingMd);
    }
  }

  .experiences {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-top: var(--spacingMd);

    @media (--tablet) {
      gap: 80px;
    }

    @media (--desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .experience {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    & .experience-header {
      flex-direction: column;
      @media (--tablet) {
        flex-direction: row;
      }
    }
    .title {
      @apply --f5;
      font-size: 14px;
      color: #293E5D;
      @media (--tablet) {
        font-size: 18px;
      }
    }
    .member{
      fill: #879AB2;
      width: 16px;
      height: 18px;
      margin-right: 4px;

      @media(--tablet) {
        margin-right: 0;
        margin-left: 4px;
      }
    }

    .description {
      margin-top: 1em;
    }

    .image {
      border: 1px solid #879AB2;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      display: flex;

      img {
        aspect-ratio: 280 / 180;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #EC4E3633;
        opacity: 0;
        transition: opacity .15s ease-in;
      }

      &:hover {
        border-color: var(--colorSBRedFlat);
        &:after {
          opacity: 0.5;
        }
      }
    }

    &.active .image {
      border-color: var(--colorSBRedFlat);
      &:after {
        opacity: 1;
      }
    }
  }
  .bg-image {
    display: none;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    right: 100px;
    top: 0;
    width: 40%;
    min-width: 240px;
    --p: 30%;
    height: 100vh;
    position: fixed;
    opacity: 0.3;
    -webkit-clip-path: polygon(var(--p) 0, 100% 0, calc(100% - var(--p)) 100%, 0 100%);
    clip-path: polygon(var(--p) 0, 100% 0, calc(100% - var(--p)) 100%, 0 100%);

    @media (--tablet) {
      display: block;
    }
  }
  & .part2, & .part3 {
    margin-top: 100px;
  }

  & .part3 {
    margin-bottom: 16px; /* Make up for available station text going out of bounds */
  }

  & .part4 {
    margin-top: calc(100px + 16px); /* Make up for available station text going out of bounds */
  }

  & .dates, & .times {
    margin-left: -50px;
    width: calc(100% + 100px);
    padding: 0 var(--spacingMd);

    @media(--tablet) {
      padding: 0;
    }
  }

  & .times {
    margin-top: 50px;

    & .start-time {
      .ui-button {
        background-color: transparent !important;
      }
    }
  }

  & .timeSlots {
    position: relative;
    width: 100%;
    height:50px;
    display: flex;

    & .timeSlot {
      display: flex;
      width: 25%;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;

      & .durationText, & .dateText {
        display: block;
        width: 100%;
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        color: #293E5D;
      }

      & .durationText {
        font-size: 18px;
      }

      & .dateText {
        font-size: 14px;
      }
    }
  }

  & .panel {
    & .pages {
      padding: var(--spacingSm);

      @media (--tablet) {
        padding: var(--spacingSm) var(--spacingLg);
      }

      @media (--desktop) {
        padding: var(--spacingMd);
      }
    }
  }

  & .powered {
    display: block;
    margin-bottom: 15rem;
    text-align: center;

    & img {
      margin-top: var(--spacingMd);
      width: 20rem;
    }
  }

  & .locationname {
    @apply --f1;
    text-align: center;
  }

  & .loadinganimation {
    text-align: center;
    padding: var(--spacingMd);
  }

  & >>> .bottom-bar {
    z-index: var(--zNavMenu);
  }

  & .separator {
    width: 100%;
    height: 1px;
    background-color: var(--colorManatee);
    position: absolute;
    left: 0;
    margin-top: 49px;
  }

  .part2 {
    margin-top: 140px;
    &+.separator {
      margin-top: 140px;
      transform: rotate(358deg);
      @media(--tablet) {
        margin-top: 90px;
      }
    }
  }
  .part3 {
    margin-top: 200px;
    &+.separator {
      margin-top: 110px;
      transform: rotate(3deg);
    }
  }
  .part4 {
    margin-top: 240px;
  }
}
</style>
